<template>
    <PageHeader :title="title" />
    
    <tablecustom 
      @search="searchB" 
      :dataParams="dataParams"
      :objParams="objParams"
      :columns="columns" 
      :rows="rows" 
      :pages="objPages"
      :showpag="true" 
      @getdata="getdata"
      @changelimit="changelimit"
      @changeFilter="changeFilter" 
      @clearFilter="clearFilter" 
      @open="open"
    />

    <!-- карточка заявки -->
    <viewBox 
      v-if="showModal == true" 
      @close="showModal = false" 
      :shortapplic="obj" 
      @getdata="getdata"
    >
    </viewBox>

</template>

<script>
import PageHeader from "@/components/page-header";
import infoblocks from '@/components/info/blocks'
import tablecustom from '@/components/globaltable/index';
import viewBox from '@/views/application/view/viewapplic'
import { Archive } from '@/API.js';
import { storeS } from "@/store";
import {mutateStatusDumpArchive} from '@/usabilityScripts/globalMutate.js'

let apiArchive = new Archive();

export default {
  components: {
    PageHeader,
    tablecustom,
    infoblocks,
    viewBox
  },
  data() {
    return {
      title: "archiveDumps",
      modalShow: false,
      statuscreated: true,
      infoblockShow: false,
      showModal: false,
      objPages: {},
      dataParams: {
          status: true,
          page: "calls"
      },
      objParams:{
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          workerId: '',
          pr1: '',
          pr2: ''
      },
      rows: [],
      columns: [
        {
          name: this.$t("phone_num"),
          text: "userPhone",
          align: "right",
          status: true
        },
        {
          name: this.$t("Status"),
          text: 'status',
          align: "left",
          status: true,
          mutate: (item) => this.mutateStatus(item).r,
          mutateClass: (item) => this.mutateStatus(item).cl
        },
        {
          name: this.$t("Manager"),
          text: "closeWorkerName",
          align: "right",
          status: true,
          mutate: (item) => item ? item : '-'
        },
      ],
    };
  },
  created() {
    this.getdata();
  },
  methods: {
    getdata(page){
      this.objParams.page = page != undefined ? page : '1';
      apiArchive.getArchiveDumps(this.objParams).then(result => {
        if(result.status == 'done'){
          this.objPages = result.data;
          this.rows = result.data.items;
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    open: function(e){
      apiArchive.getArchiveDump(e.requestId).then(res => {
        if(res.status === 'done') {
          this.obj = res.data;
          this.showModal = true;
        } else {
          this.$toast.error(this.$t('err'))
        }
      })
    },
    changelimit: function(limit){
        this.objParams.pagelimit = limit;
        this.getdata();
    },
    searchB(e){
      this.objParams.search = e
      this.getdata();
    },
    changeFilter(name, value){
      if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else {
          this.objParams[name] = value;
        }
        this.getdata();
      } else {
        this.objParams = {
          page: '1',
          pagelimit: '10',
          search: '',
          statusArchive: '',
          taskName: '',
          priority: '',
          workerId: '',
          pr1: '',
          pr2: ''
        },
        this.getdata()
      }
    },
    mutateStatus(e){
      return mutateStatusDumpArchive(e)
    },
  },
  computed: {
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    },
    check() {
      return storeS.checks
    },
  },
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.howto_block {
  margin-left:10px;
}
.howto_block i {
  font-size: 23px;
  opacity: 0.5;
}
.howto_block i:hover{
  opacity: 1;
}

.howto_block {
  position: relative;
  display: inline-block;
}

.howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
}

.howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
</style>
